<template>
  <div class="header-main">
    <div class="header-main__container">
      <router-link
        :to="{ name: 'home' }"
        class="header-main__logo"
        title="Торговая площадка товаров из Турции"
      >
        <img src="/static/images/logo.png" alt="logo" />
      </router-link>
      <OpenCatalogButtonComponent />
      <div class="header-main__input">
        <InputComponent
          @input="$emit('search', search)"
          v-model.trim="search"
          icon-position="left"
          type="search"
          placeholder="Искать товары"
        />
        <SearchResultsDropdownComponent
          v-show="search"
          :items="products"
          v-slot="{ item }"
          buttonTitle="Показать все"
          :route="{ path: `/catalog?title=${search}` }"
          :loading="loading"
        >
          <ProductCardSearchVersionComponent :data="item" />
        </SearchResultsDropdownComponent>
      </div>
      <div class="header-main__actions">
        <router-link :to="{ name: 'user', params: { id: 2 } }" class="header-main__action" title="Избранное">
          <RemixIconComponent category="Health" name="heart-2-line" />
          <span v-show="favoritesLength" class="header-main__action-count">{{ favoritesLength }}</span>
        </router-link>
        <router-link :to="{ name: 'cart' }" class="header-main__action" title="Корзина">
          <RemixIconComponent category="Finance" name="shopping-cart-line" />
          <span v-show="cartItemsLength" class="header-main__action-count">{{ cartItemsLength }}</span>
        </router-link>
        <a href="#" @click.prevent="loginUser" class="header-main__action" title="Личный кабинет">
          <RemixIconComponent category="User" name="user-line" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import OpenCatalogButtonComponent from "components/header/components/OpenCatalogButtonComponent.vue";
import SearchResultsDropdownComponent from "components/header/components/SearchResultsDropdownComponent.vue";
import ProductCardSearchVersionComponent from "components/product/ProductCardSearchVersionComponent.vue";

export default {
  name: "HeaderMainComponent",
  props: {
    cartItemsLength: Number,
    products: Array,
    loading: Boolean,
  },
  data() {
    return {
      search: null,
    };
  },
  watch: {
    "$route.name"() {
      this.search = null;
    },
    "$route.params"() {
      this.search = null;
    },
  },
  computed: {
    favoritesLength() {
      return this.$store.state.auth.favorites.length;
    },
  },
  methods: {
    loginUser() {
      if (this.$store.state.apollo_token) {
        if (this.$route.name !== "user") this.$router.push({ name: "user" });
      } else {
        this.showLoginModal();
      }
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
  components: {
    ProductCardSearchVersionComponent,
    SearchResultsDropdownComponent,
    OpenCatalogButtonComponent,
    InputComponent,
    RemixIconComponent,
    FileIconComponent,
  },
};
</script>

<style lang="stylus">
.header-main {
  display flex
  align-items center
  justify-content center
  background var(--white)
  padding 0 15px
  +below(860px) {
    display none
  }

  &__container {
    display inline-flex
    align-items center
    justify-content space-between
    max-width var(--main_width)
    width 100%
    padding 16px 0
    gap 32px

    .btn {
      flex-shrink 0
    }
  }

  &__input {
    position relative
    width 100%
    display flex
    align-items center

    .input {
      width 100%

      &:focus-within + .search-dropdown {
        opacity 1
        visibility visible
        top 100%
      }
    }
  }

  &__actions {
    display flex
    align-items center
    gap 24px
    flex-shrink 0
  }

  &__action {
    width 24px
    height 24px
    position relative

    .icon {
      width 100%
      height 100%

      svg path {
        fill var(--body-color-muted)
      }
    }
  }

  &__action-count {
    display inline-flex
    align-items center
    justify-content center
    text-align center
    absolute right -5px top -5px
    height 18px
    min-width 18px
    background: var(--red-500);
    border: 1px solid var(--white);
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 600;
    font-size: 0.75em;
    color: var(--white);
  }

  &__logo {
    width 125px
    height 38px
    flex-shrink 0
    display: flex
    align-items: center
  }
}
</style>
