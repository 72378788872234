<template>
  <div class="mobile-menu">
    <div class="mobile-menu__top">
      <!--      <a href="#" class="header-top__icon-link" title="Ваш город">-->
      <!--        <RemixIconComponent category="Map" name="map-pin-2-line" />-->
      <!--        <span>Москва и Подмосковье</span>-->
      <!--      </a>-->
      <a
        v-if="phone.value"
        :href="phone.value | phone"
        class="header-top__icon-link"
        title="Служба поддержки"
      >
        <RemixIconComponent category="Device" name="phone-line" />
        <span>{{ phone.value }}</span>
      </a>
      <div class="mobile-menu__top-right">
        <div class="mobile-menu__action">
          <a class="header-top__icon-link header-top__icon-link--no-padding" :data-lang="activeLang.id">
            <RemixIconComponent category="Editor" name="translate-2" />
            <span>{{ activeLang.title }}</span>
          </a>
          <ul class="drop-down">
            <li v-for="(lang, i) in langs" :key="i">
              <a class="header-top__icon-link" @click="setLang(lang.id)">
                {{ lang.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="mobile-menu__action">
          <a
            class="header-top__icon-link header-top__icon-link--no-padding"
            :data-currency="activeCurrency.id"
          >
            <span>{{ activeCurrency.symbol }}</span>
            <span>{{ activeCurrency.title }}</span>
          </a>
          <ul class="drop-down drop-down--left">
            <li v-for="(item, i) in currencies" :key="i">
              <a class="header-top__icon-link" @click="setCurrency(item.id)">
                {{ item.symbol }} {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mobile-menu__body">
      <OpenCatalogButtonComponent />
      <router-link :to="{ name: 'user' }" class="mobile-menu__nav-link">
        <RemixIconComponent category="User" name="user-line" />
        <span>Личный кабинет</span>
      </router-link>
      <router-link :to="{ name: 'user', params: { id: 2 } }" class="mobile-menu__nav-link">
        <RemixIconComponent category="Health" name="heart-3-line" />
        <span>Избранное</span>
      </router-link>
      <router-link :to="{ name: 'cart' }" class="mobile-menu__nav-link">
        <RemixIconComponent category="Finance" name="shopping-cart-line" />
        <span>Корзина</span>
      </router-link>
    </div>
    <div class="mobile-menu__footer">
      <ul class="mobile-menu__links">
        <!--        <li>-->
        <!--          <router-link :to="{ name: 'marketplace' }">О компании</router-link>-->
        <!--        </li>-->
        <li>
          <router-link :to="{ name: 'shops' }">Бренды</router-link>
        </li>
        <li v-for="(page, i) in pages" :key="i">
          <router-link :to="{ name: 'static', params: { link: page.link } }">{{ page.title }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'faq' }">Вопросы и ответы</router-link>
        </li>
        <li>
          <router-link :to="{ hash: 'contacts' }">Контакты</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import OpenCatalogButtonComponent from "components/header/components/OpenCatalogButtonComponent.vue";
import axios from "axios";

export default {
  name: "MobileMenuComponent",
  data: () => ({
    googleTranslateConfig: {
      lang: "ru",
    },
    activeLang: {
      id: "ru",
      title: "Русский",
    },
    langs: [
      {
        id: "ru",
        title: "Русский",
      },
      {
        id: "en",
        title: "Английский",
      },
      {
        id: "tr",
        title: "Турецкий",
      },
    ],
    activeCurrency: {
      id: "RUB",
      title: "Рубль",
      symbol: "₽",
    },
    currencies: [
      {
        id: "RUB",
        title: "Рубль",
        symbol: "₽",
      },
      {
        id: "TRY",
        title: "Türk lirası",
        symbol: "₺",
      },
      {
        id: "USD",
        title: "Dollar",
        symbol: "$",
      },
      {
        id: "EUR",
        title: "Euro",
        symbol: "€",
      },
    ],
  }),
  computed: {
    pages() {
      return this.$store.state.pages && this.$store.state.pages.length
        ? this.$store.state?.pages.filter((p) => p.is_main)
        : [];
    },
    phone() {
      return this.$store.state.marketplace.communications
        ? this.$store.state.marketplace.communications.find(
            (item) => item.type.code === this.$store.state._types.PHONE
          )
        : undefined;
    },
  },
  mounted() {
    this.$router.history.listen(() => {
      this.$store.state._overlays.showMobileMenu = false;
    });

    let currency = this.$cookie.get("currency") || "RUB";
    currency = this.currencies.find((c) => c.id === currency);
    if (currency) {
      this.activeCurrency = currency;
    }
  },
  created() {
    let lang = this.$store.state.session.lang || "ru";
    lang = this.langs.find((l) => l.id === lang);
    if (lang) {
      this.activeLang = lang;
    }
  },
  methods: {
    setLang(code) {
      // Сохраняю в сессии и куки
      this.$cookie.set("googtrans", "/auto/" + code);
      this.$cookie.set("googtrans", "/auto/" + code, {
        domain: "." + document.domain,
      });
      axios({
        method: "post",
        url: "/language",
        data: {
          lang: code,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        location.reload();
      });
    },
    setCurrency(code) {
      // Сохраняю в сессии и куки
      this.$cookie.set("currency", code, {
        SameSite: "none",
        Secure: true,
      });
      location.reload();
    },
  },
  components: { OpenCatalogButtonComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.mobile-menu {
  display grid
  padding 15px

  &__top {
    display flex
    align-items center
		justify-content space-between
		gap: 10px
    border-bottom 1px solid var(--blue-500)

		&-right {
			display flex
			align-items center
			gap: 10px
		}
  }

  &__body {
    display grid
    grid-gap 2px
    border-bottom 1px solid var(--blue-500)
    padding 15px 0
  }

  &__action {
    position relative
    display inline-flex

    & .drop-down {
      visibility hidden
      opacity 0
      absolute left bottom 1px
      transform translateY(100%)

			&--left {
				position absolute
				right 0
				left auto
				bottom 1px
			}
    }

    & .drop-down:hover,
    & > a:hover + .drop-down {
      visibility visible
      opacity 1
      z-index 999
    }
  }

  &__nav-link {
    height 52px
    padding 0 16px
    gap 8px
    background: var(--blue-100);
    border-radius: 8px;
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-500);

    &:hover {
      color: var(--gray-500);
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--gray-500)
      }
    }
  }

  &__footer {
    padding 15px 0
  }

  &__links {
    display grid
    grid-gap 16px
    padding 15px

    a {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--body-color-muted);
    }
  }
}
</style>
