<template>
  <div class="header-bottom">
    <div class="header-bottom__container">
      <ul class="header-bottom__list">
        <li>
          <router-link
            :to="{ name: 'catalog', query: { in_bestseller: true, page_title: 'Хиты продаж' } }"
            class="header-bottom__link"
          >
            <RemixIconComponent category="Finance" name="trophy-line" />
            <span>Хиты продаж</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'catalog',
              query: {
                ordering: JSON.stringify([{ orderColumn: 'created_at', orderBy: 'desc' }]),
                page_title: 'Новинки',
              },
            }"
            class="header-bottom__link"
          >
            <RemixIconComponent category="System" name="star-line" />
            <span>Новинки</span>
          </router-link>
        </li>
        <li v-for="(item, i) in categories" :key="i">
          <router-link
            :to="{ name: 'catalog', params: { id: item.id }, query: { page_title: item.title } }"
            class="header-bottom__link"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "HeaderBottomComponent",
  computed: {
    categories() {
      return this.$store.state.categories;
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.header-bottom {
  display flex
  justify-content center
  align-items center
  background var(--white)
  box-shadow: inset 0 8px 24px rgba(0, 0, 0, 0.05);
  padding 0 15px
  +below(860px) {
    display none
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display inline-flex
    align-items center
    overflow hidden
  }

  &__list {
    display inline-flex
    align-items center
    justify-content flex-start
    gap 32px
    flex-shrink 0

    li {
      display flex
      align-items center
    }
  }

  &__link {
    padding 16px 0
    gap 4px
    display inline-flex
    align-items center
    font-weight: normal;
    font-size: 0.875em;
    line-height: 19px;
    color: var(--gray-600);
    transition var(--transition)

    &:hover {
      color: var(--gray-800)

      .icon svg path {
        fill var(--gray-800)
      }
    }

    span {
      display inline-flex
      align-items center
    }

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--gray-600)
        transition var(--transition)
      }
    }
  }
}
</style>
