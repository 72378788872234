<template>
  <ul class="catalog-list">
    <li v-for="(category, i) in categories" :key="i">
      <router-link
        :to="{ name: 'catalog', params: { id: category.id }, query: { page_title: category.title } }"
        class="catalog-list__item shadow"
      >
        <ImgComponent class="catalog-list__item-img" :head_img="category.head_img" width="200" height="200" />
        <div class="catalog-list__item-cover">
          <h4 class="catalog-list__item-title">
            {{ category.title }}
          </h4>
        </div>
      </router-link>
      <ul class="catalog-list__categories">
        <li v-for="(child, index) in category.childs" :key="index">
          <router-link
            :to="{ name: 'catalog', params: { id: child.id }, query: { page_title: child.title } }"
          >
            {{ child.title }}
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CatalogCategoryList",
  props: {
    categories: Array,
  },
  methods: {
    handleCategoryClick(item) {
      this.$router
        .push({
          name: "catalog",
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.$store.state._overlays.showCatalog = false;
        })
        .catch(() => {
          this.$store.state._overlays.showCatalog = false;
        });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.catalog-list {
  display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-gap: 24px
  +below(1024px) {
		grid-template-columns: repeat(2, 1fr)
  }

  > li {
    display: flex;
    flex-direction: column;
    break-inside: avoid-column;
    /*display: inline-block;*/
    margin-bottom 24px
    gap 16px
  }

	&__item {
		position: relative
		ratio(300, 200);
		border-radius: 16px
		overflow: hidden

		&:hover img {
			scale 1.1
		}

		&-cover {
			position: absolute
			left 0
			right 0
			top: 0
			bottom: 0
			z-index: 1;
			padding: 16px 8px
			display: flex
			flex-direction: column
			justify-content flex-end
		}

		&-title {
			font-weight: normal;
			font-size: 1.125em;
			line-height: 28px;
			display: flex;
			align-items: center;
			margin 0
			color: var(--white)
		}
	}

  &__categories {
		padding: 0 8px
		display: flex
		flex-direction: column
		gap: 8px

    a {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--gray-500);
			padding: 8px 0
    }
  }
}
</style>
