import "es6-promise/auto";
import createApp from "./app";
import LoginModal from "components/modals/components/LoginModal.vue";
import PRODUCTS_PAGINATE from "gql/queries/products_paginate.graphql";
import Echo from "laravel-echo";
import { getCookie } from "./utils.js";

const { app, router, store, apollo } = createApp(window.__INITIAL_STATE__._env);

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
  store.state._window_size = window.innerWidth;
  store.state.session = window.__INITIAL_STATE__.session || {};
  store.state.isMobile = window.__INITIAL_STATE__.isMobile || false;
  store.state._env = window.__INITIAL_STATE__._env || {};
  store.state.apollo_token = window.__INITIAL_STATE__.apollo_token || undefined;
}

router.onReady(async () => {
  if (localStorage.cartItems) {
    let cart = JSON.parse(localStorage.cartItems);
    let ids = cart.map((i) => i.item?.id);
    if (ids && ids.length) {
      try {
        await apollo.defaultClient
          .mutate({
            mutation: PRODUCTS_PAGINATE,
            variables: {
              ids: ids,
              first: 9999, // да, костыль, а че делать, Рамиз занят
            },
          })
          .then(({ data }) => {
            if (data.products_paginate.data && data.products_paginate.data.length) {
              data.products_paginate.data.forEach((p) => {
                cart.forEach((c) => {
                  if (c.item.id === p.id) {
                    c.item = p;
                  }
                });
              });
            }
            localStorage.cartItems = JSON.stringify(cart);
            store.state.auth.cart = cart;
          });
      } catch (e) {
        console.error("Client data load error", e);
      }
    }
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("no_auth") === "1") {
    store.state._modals.push({
      component: LoginModal,
    });
    urlParams.delete("no_auth");
    history.replaceState(null, "", location.pathname + urlParams);
  }
  router.beforeEach((to, from, next) => {
    if (to.meta.authRequired && !store.state.auth.user) {
      store.state._modals.push({
        component: LoginModal,
      });
      next(false);
    } else {
      next();
    }
  });
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const asyncDataHooks = matched.map((c) => c.asyncData).filter((_) => _);
    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to, apollo })))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

if (location.protocol === "https:" && navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js").then((value) => {
    console.log(value);
  });
}

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  key: store.state._env.PUSHER_APP_KEY,
  cluster: store.state._env.PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: store.state._env.MEDIA_ENDPOINT + "/broadcasting/auth",
  // wsHost: "localhost", // Используйте localhost для локального сервера
  // wsPort: 8000,
  auth: {
    headers: {
      Authorization: "Bearer " + store.state.apollo_token,
    },
  },
});
// window.Echo.connector.pusher.connection.bind("connected", () => {
//   console.log("Connected to Pusher through Echo");
//   alert("Connected to Pusher through Echo");
// });
//
// window.Echo.connector.pusher.connection.bind("error", (error) => {
//   console.error("Connection error:", error);
//   alert("Connection error: " + error.message);
// });
// console.log(decodeURIComponent(getCookie("admin-token")));
