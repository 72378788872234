<template>
  <div class="search-dropdown" v-if="items && items.length">
    <div :style="{ opacity: loading ? 0.7 : 1 }" class="search-dropdown__items">
      <div v-for="(item, i) in items" :key="i" class="search-dropdown__item">
        <slot :item="item" />
      </div>
    </div>
    <router-link :to="route" class="btn btn--main-inv btn--md">
      <span>Все результаты</span>
      <RemixIconComponent category="System" name="arrow-right-s-line" />
    </router-link>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "SearchResultsDropdownComponent",
  components: { RemixIconComponent },
  props: {
    items: Array,
    buttonTitle: String,
    loading: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
  },
};
</script>

<style lang="stylus">
.search-dropdown {
  position absolute
  top calc(100% + 10px)
  left 0
  right 0
  z-index 10
  background var(--white)
  border-radius 0 0 var(--main_radius) var(--main_radius)
  box-shadow: 0 10px 60px -15px rgba(0, 0, 0, 0.4);
  padding 10px
  opacity 0
  visibility hidden
  display flex
  flex-direction column
  align-items center
  transition var(--transition)

  &__items {
    display flex
    flex-direction column
    gap 8px
    max-height 60vh
    overflow-y auto
    width 100%
    transition var(--transition)
  }

  &__item {
    flex-shrink 0
  }
}
</style>
