<template>
  <button type="button" @click="showCatalog" class="btn btn--lg btn--green-inv">
    <RemixIconComponent category="System" name="close-line" v-if="$store.state._overlays.showCatalog" />
    <RemixIconComponent category="System" name="menu-line" v-else />
    <span>Каталог товаров</span>
  </button>
</template>
<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "OpenCatalogButtonComponent",
  methods: {
    showCatalog() {
      Object.keys(this.$store.state._overlays).forEach((key) => {
        if (key !== "showCatalog") {
          this.$store.state._overlays[key] = false;
        }
      });
      this.$store.state._overlays.showCatalog = !this.$store.state._overlays.showCatalog;
    },
  },
  components: { RemixIconComponent },
};
</script>
