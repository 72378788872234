<template>
  <div class="header-mobile">
    <div class="header-mobile__top">
      <button
        type="button"
        class="btn btn--lg btn--green-inv"
        title="Каталог товаров"
        @click="toggleMobileMenu"
      >
        <RemixIconComponent
          category="System"
          name="menu-line"
          v-if="!$store.state._overlays.showMobileMenu"
        />
        <RemixIconComponent category="System" name="close-line" v-else />
      </button>
      <router-link
        :to="{ name: 'home' }"
        class="header-main__logo"
        title="Торговая площадка товаров из Турции"
      >
        <!-- <FileIconComponent name="logo" /> -->
        <img src="/static/images/logo.png" alt="logo" />
      </router-link>
      <a
        href="#"
        class="header-main__action header-mobile__search"
        @click="showSearch = !showSearch"
        title="Поиск по сайту"
      >
        <RemixIconComponent category="System" name="search-2-line" />
      </a>
      <router-link :to="{ name: 'cart' }" class="header-main__action" title="Корзина">
        <RemixIconComponent category="Finance" name="shopping-cart-line" />
        <span v-show="cartItemsLength" class="header-main__action-count">{{ cartItemsLength }}</span>
      </router-link>
    </div>
    <div class="header-main__input">
      <InputComponent
        @input="$emit('search', search)"
        v-model.trim="search"
        v-show="showSearch"
        icon-position="left"
        type="search"
        placeholder="Искать товары"
      />
      <SearchResultsDropdownComponent
        v-show="search"
        :items="products"
        v-slot="{ item }"
        buttonTitle="Показать все"
        :route="{ path: `/catalog?title=${search}` }"
        :loading="loading"
      >
        <ProductCardSearchVersionComponent :data="item" />
      </SearchResultsDropdownComponent>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SearchResultsDropdownComponent from "components/header/components/SearchResultsDropdownComponent.vue";
import ProductCardSearchVersionComponent from "components/product/ProductCardSearchVersionComponent.vue";

export default {
  name: "HeaderMobileComponent",
  props: {
    cartItemsLength: Number,
    products: Array,
    loading: Boolean,
  },
  data() {
    return {
      showSearch: false,
      search: null,
    };
  },
  watch: {
    "$route.name"() {
      this.search = null;
    },
    "$route.params"() {
      this.search = null;
    },
  },
  methods: {
    toggleMobileMenu() {
      Object.keys(this.$store.state._overlays).forEach((key) => {
        if (key !== "showMobileMenu") {
          this.$store.state._overlays[key] = false;
        }
      });
      this.$store.state._overlays.showMobileMenu = !this.$store.state._overlays.showMobileMenu;
    },
  },
  components: {
    ProductCardSearchVersionComponent,
    SearchResultsDropdownComponent,
    InputComponent,
    FileIconComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.header-mobile {
  padding 15px
  display none
  grid-gap 16px
  background: var(--white);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  +below(860px) {
    display grid
  }

  &__top {
    display flex
    align-items center
    justify-content flex-start
    width 100%
    gap 16px
  }

  &__search {
    margin-left auto
  }
}
</style>
