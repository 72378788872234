<template>
  <img
    :src="src || source"
    :alt="head_img ? head_img.alt : defaultAlt"
    loading="lazy"
    class="img"
    decoding="async"
    :class="{ lazy: loader, loaded }"
    @load="loaded = true"
    @error="loaded = true"
  />
</template>

<script>
export default {
  name: "ImgComponent",
  props: {
    src: String,
    head_img: Object,
    defaultAlt: {
      type: String,
      default() {
        return "";
      },
    },
    quality: {
      type: String,
      default: "99",
    },
    width: [Number, String],
    height: [Number, String],
    strategy: {
      type: [Number, String],
      default() {
        return 2;
      },
    },
    loader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    source() {
      let link = null;
      if (this.head_img && this.head_img.id) {
        const urlParams = new URLSearchParams();
        if (this.quality) urlParams.append("quality", this.quality);
        if (this.width) urlParams.append("width", "" + this.width);
        if (this.height) urlParams.append("height", "" + this.height);
        const urlParamsString = urlParams.toString();
        if (this.head_img.links) {
          link = this.head_img.links.find((i) => i.query === urlParamsString);
          if (link) {
            return this.$store.state._env.MEDIA_ENDPOINT + link.path;
          }
        }
        return `${this.$store.state._env.MEDIA_ENDPOINT}/action/img/${this.head_img.id}?${urlParamsString}`;
      }
      return "/static/images/no-image.png";
    },
  },
};
</script>

<style lang="stylus">
.img {
  object-fit cover
  object-position center
}

.lazy {
  opacity 0
  transition var(--transition)

  &.loaded {
    opacity 1
  }
}
</style>
