<template>
  <a class="telegram-bot" href="https://t.me/sasafaq_bot" target="_blank" rel="nofollow">
    <FileIconComponent class="telegram-bot__icon" name="telegram" />
    <span class="telegram-bot__info">
      <span class="telegram-bot__title">Telegram Bot</span>
      <span class="telegram-bot__description">Переходите для обучения</span>
    </span>
  </a>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "TelegramBotComponent",
  components: { FileIconComponent },
};
</script>

<style lang="stylus">
.telegram-bot {
	display flex
	align-items flex-end
	gap: 6px
	color: var(--white)
	font-size: 0.875rem
	line-height: 1
	padding: 8px
	border-radius: 10px
	border: 1px solid var(--white)
	align-self: flex-start

	&:hover {
		color: var(--white)
		border-color: var(--accent)
	}

	&__icon {
		width 30px
		height 30px
		border-radius: 5px
		overflow hidden
	}

	&__info {
		display flex
		flex-direction column
		gap: 4px
		font-size: 0.8rem
	}

	&__title {
		font-size 0.7rem
	}

	&__description {

	}
}
</style>
