<template>
  <div class="catalog-aside">
    <InputComponent placeholder="Поиск по каталогу" type="search" icon-position="left" v-model="query" />
    <ul class="catalog-aside__list">
      <li v-if="value && !(query && query.length)">
        <a href="#" class="catalog-aside__active" @click.prevent="handlePrevClick">
          <RemixIconComponent category="System" name="arrow-left-line" />
          <span>{{ value.title }}</span>
        </a>
      </li>
      <li v-for="(item, i) in categoriesList" :key="i">
        <a
          href="#"
          class="catalog-aside__item"
          :class="{ 'catalog-aside__item--active': value && value.id === item.id }"
          @click.prevent="handleCategoryClick(item)"
        >
          <span>{{ item.title }}</span>
          <RemixIconComponent
            v-if="item.childs && item.childs.length"
            category="System"
            name="arrow-right-s-line"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "CatalogMenuAsideComponent",
  props: {
    categories: Array,
    value: Object,
  },
  data() {
    return {
      query: undefined,
    };
  },
  computed: {
    categoriesList() {
      if (typeof window !== "undefined") {
        if (this.query && this.query.length !== 0) {
          return this.categories.filter((item) => item.title.indexOf(this.query) !== -1);
        }
        if (window.innerWidth <= 860 && this.value) {
          return this.value.childs;
        }
        return this.categories;
      } else {
        return this.categories;
      }
    },
  },
  methods: {
    handlePrevClick() {
      this.$emit("input", undefined);
    },
    handleCategoryClick(item) {
      if (item.childs && item.childs.length) {
        this.$emit("input", item);
      } else {
        this.$router
          .push({
            name: "catalog",
            params: {
              id: item.id,
            },
          })
          .then(() => {
            this.$store.state._overlays.showCatalog = false;
          })
          .catch(() => {
            this.$store.state._overlays.showCatalog = false;
          });
      }
    },
  },
  components: { RemixIconComponent, InputComponent },
};
</script>

<style lang="stylus">
.catalog-aside {
  display grid
  grid-gap 15px
  align-items start

  > .input {
    +above(861px) {
      display none
    }
  }

  &__active {
    display flex
    align-items center
    gap 16px
    padding 16px
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--body-color);
    width 100%
    border-bottom 1px solid var(--blue-500)
    +above(861px) {
      display none
    }

    &:hover {
      color: var(--gray-500);
      border-color var(--blue-500)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--gray-500)
      }
    }
  }

  &__list {
    display grid
    align-items start

    li {
      display flex
      width 100%
    }
  }

  &__item {
    width 100%
    border-radius: var(--small_radius);
    padding 8px
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-500);
    transition var(--transition)
    gap 4px
    +below(860px) {
      padding 16px
      border-radius 0
      border-bottom 1px solid var(--blue-500)
    }

    span {
      width 100%
    }

    .icon {
      width 20px
      height 20px
      +above(861px) {
        display none
      }

      svg path {
        fill var(--gray-500)
      }
    }

    &:hover {
      color: var(--gray-500);
      +above(861px) {
        background: var(--blue-200);
      }
      +below(860px) {
        border-color var(--blue-500)
      }
    }

    &--active {
      +above(861px) {
        color var(--accent)
        background: var(--blue-200);

        &:hover {
          color var(--accent)
        }
      }
    }
  }
}
</style>
