import cart_page from "@/graphql/pages/CartPage.graphql";
import cart_page_no_auth from "@/graphql/pages/CartPageNoAuth.graphql";
import CartAddItems from "@/graphql/mutations/CartAddItems.graphql";
import CartDeleteItem from "@/graphql/mutations/CartDeleteItem.graphql";
import FavoriteCreateOrDelete from "@/graphql/mutations/FavoriteCreateOrDelete.graphql";
import favorites from "@/graphql/queries/favorites.graphql";

async function GET_CART_ITEMS({ state }, { apollo }) {
  let token = state.apollo_token;
  await apollo
    .query({
      query: token ? cart_page : cart_page_no_auth,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      if (data.carts) state.auth.cart = data.carts.filter((i) => i.item);
      state.auth.deliveries = data.deliveries;
      state.categories = data.categories;
      state.pages = data.pages;
      state.marketplace = data.marketplace;
      if (data.exchange_rates) state.exchange_rates = data.exchange_rates;
    });
}

async function CART_ADD_ITEMS({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: CartAddItems,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function CART_DELETE_ITEMS({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: CartDeleteItem,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function FAVORITE_CREATE_OR_DELETE({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: FavoriteCreateOrDelete,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function GET_FAVORITES({ state }, { apollo }) {
  let token = state.apollo_token;
  await apollo
    .query({
      query: favorites,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.favorites = data.favorites.map((item) => item.item_id);
    });
}

export default Object.assign({
  GET_CART_ITEMS,
  CART_ADD_ITEMS,
  CART_DELETE_ITEMS,
  FAVORITE_CREATE_OR_DELETE,
  GET_FAVORITES,
});
