<template>
  <div v-if="socials && socials.length" class="socials">
    <a v-for="(item, i) in socials" :key="i" :href="item.link" target="_blank" rel="nofollow">
      <figure class="icon" v-html="item.value"></figure>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialsComponent",
  props: {
    socials: [],
  },
};
</script>

<style lang="stylus">
.socials {
  display flex
  align-items center
  gap 16px
  justify-content flex-start
  margin-top 16px

  a {
    display flex

    .icon {
      width 40px
      height 40px
    }
  }
}
</style>
