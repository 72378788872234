<template>
  <div class="header-top">
    <div class="header-top__container">
      <!--      <a href="#" class="header-top__icon-link" title="Ваш город">-->
      <!--        <RemixIconComponent category="Map" name="map-pin-2-line" />-->
      <!--        <span>Москва и Подмосковье</span>-->
      <!--      </a>-->
      <nav class="header-top__links">
        <ul>
          <li>
            <router-link :to="{ name: 'marketplace' }">О компании</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'shops' }">Бренды</router-link>
          </li>
          <li v-for="(page, i) in pages" :key="i">
            <router-link :to="{ name: 'static', params: { link: page.link } }">{{ page.title }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'faq' }">Вопросы и ответы</router-link>
          </li>
          <li>
            <router-link :to="{ hash: 'contacts' }">Контакты</router-link>
          </li>
        </ul>
      </nav>
      <div class="header-top__actions">
        <a v-if="phone" :href="phone.value | phone" class="header-top__icon-link" title="Служба поддержки">
          <RemixIconComponent category="Device" name="phone-line" />
          <span>{{ phone.value }}</span>
        </a>
        <div class="header-top__action">
          <a class="header-top__icon-link header-top__icon-link--no-padding" :data-lang="activeLang.id">
            <RemixIconComponent category="Editor" name="translate-2" />
            <span>{{ activeLang.title }}</span>
          </a>
          <ul class="drop-down">
            <li v-for="(lang, i) in langs" :key="i">
              <a class="header-top__icon-link" @click="setLang(lang.id)">
                {{ lang.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="header-top__action">
          <a
            class="header-top__icon-link header-top__icon-link--no-padding"
            :data-currency="activeCurrency.id"
          >
            <span>{{ activeCurrency.symbol }}</span>
            <span>{{ activeCurrency.title }}</span>
          </a>
          <ul class="drop-down drop-down--left">
            <li v-for="(item, i) in currencies" :key="i">
              <a class="header-top__icon-link" @click="setCurrency(item.id)">
                {{ item.symbol }} {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import axios from "axios";

export default {
  name: "HeaderTopComponent",
  data: () => ({
    activeLang: {
      id: "ru",
      title: "Русский",
    },
    langs: [
      {
        id: "ru",
        title: "Русский",
      },
      {
        id: "en",
        title: "Английский",
      },
      {
        id: "tr",
        title: "Турецкий",
      },
    ],
    activeCurrency: {
      id: "RUB",
      title: "Рубль",
      symbol: "₽",
    },
    currencies: [
      {
        id: "RUB",
        title: "Рубль",
        symbol: "₽",
      },
      {
        id: "TRY",
        title: "Türk lirası",
        symbol: "₺",
      },
      {
        id: "USD",
        title: "Dollar",
        symbol: "$",
      },
      {
        id: "EUR",
        title: "Euro",
        symbol: "€",
      },
    ],
  }),
  computed: {
    pages() {
      return this.$store.state.pages && this.$store.state.pages.length
        ? this.$store.state?.pages.filter((p) => p.is_main)
        : [];
    },
    phone() {
      return this.$store.state.marketplace.communications
        ? this.$store.state.marketplace.communications.find(
            (item) => item.type.code === this.$store.state._types.PHONE
          )
        : undefined;
    },
  },
  created() {
    let lang = this.$store.state.session.lang || "ru";
    lang = this.langs.find((l) => l.id === lang);
    if (lang) {
      this.activeLang = lang;
    }
  },
  mounted() {
    let currency = this.$cookie.get("currency") || "RUB";
    currency = this.currencies.find((c) => c.id === currency);
    if (currency) {
      this.activeCurrency = currency;
    }
  },
  methods: {
    setLang(code) {
      // Сохраняю в сессии и куки
      this.$cookie.set("googtrans", "/auto/" + code);
      this.$cookie.set("googtrans", "/auto/" + code, {
        domain: "." + document.domain,
      });
      axios({
        method: "post",
        url: "/language",
        data: {
          lang: code,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        location.reload();
      });
    },
    setCurrency(code) {
      // Сохраняю в сессии и куки
      this.$cookie.set("currency", code, {
        SameSite: "none",
        Secure: true,
      });
      location.reload();
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/drop-down.styl"
.header-top {
  display flex
  align-items center
  justify-content center
  background var(--white)
  border-bottom 1px solid var(--blue-300)
  height 50px
  padding 0 15px
  +below(860px) {
    display none
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display flex
    align-items center
    justify-content space-between
    gap 8px
  }

  &__icon-link {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    color: var(--gray-300);
    display inline-flex
    align-items center
    gap 4px
    padding 16px 0
		white-space: nowrap

    &--no-padding {
      padding 0
    }

    &:hover {
      color: var(--body-color-muted);

      .icon svg path {
        fill var(--body-color-muted)
      }
    }

    .icon {
      width 18px
      height 18px

      svg path {
        fill var(--gray-300)
      }
    }
  }

  &__action {
    position relative
    display inline-flex
    margin 16px 0

    & .drop-down {
      visibility hidden
      opacity 0
      absolute left bottom 1px
      transform translateY(100%)

			&--left {
				position absolute
				right 0
				left auto
				bottom 1px
			}
    }

    & .drop-down:hover,
    & > a:hover + .drop-down {
      visibility visible
      opacity 1
      z-index 999
    }
  }

  &__actions {
    display inline-flex
    align-items center
    gap 16px
  }

  &__links {
    display inline-flex
    align-items center
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;

    ul {
      display flex
      align-items center
      gap 32px
      +below(990px) {
        gap 16px
      }
    }

    a {
      color: var(--gray-300);
      padding 16px 0

      &:hover {
        color: var(--body-color-muted);
      }
    }
  }
}
</style>
