var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"input",class:{
    'input--filled': !!_vm.value,
    'input--error': !!_vm.error,
    'input--icon-left': _vm.iconPosition === 'left',
    'input--icon-right': _vm.iconPosition === 'right',
    'input--disabled': _vm.disabled,
  }},[(_vm.title)?_c('span',{staticClass:"input__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('span',{staticClass:"input__container"},[(_vm.iconPosition)?_c('button',{staticClass:"input__action",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._t("icon",function(){return [_c('RemixIconComponent',{attrs:{"category":"System","name":"search-2-line"}})]})],2):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"input__field",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter', $event.target.value)},"input":_vm.handleInput,"blur":function($event){return _vm.$emit('blur', $event)}}})]),(_vm.subtitle)?_c('span',{staticClass:"input__subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }