<template>
  <div class="catalog">
    <div class="catalog__container">
      <div class="catalog__header">
        <h2 class="catalog__title">Каталог</h2>
        <button
          type="button"
          class="btn btn--sm btn--hollow-dark"
          @click="$store.state._overlays.showCatalog = false"
        >
          <RemixIconComponent category="System" name="close-line" />
          <span>Закрыть</span>
        </button>
      </div>
      <div class="catalog__content">
        <CatalogMenuAsideComponent :categories="categories" v-model="activeCategory" />
        <div class="catalog__body" v-if="activeCategory">
          <h3 class="catalog__category-title">{{ activeCategory.title }}</h3>
          <CatalogCategoryList :categories="activeCategory.childs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CatalogMenuAsideComponent from "components/catalog_menu/components/CatalogMenuAsideComponent.vue";
import CatalogCategoryList from "components/catalog_menu/components/CatalogCategoryList.vue";
import MENU_CATEGORIES from "@/graphql/queries/categories.graphql";

export default {
  name: "CatalogMenuComponent",
  data() {
    return {
      activeCategory: undefined,
      categories: [],
    };
  },
  mounted() {
    if (window.innerWidth <= 860) {
      this.activeCategory = undefined;
    }
    this.$router.history.listen(() => {
      this.$store.state._overlays.showMobileMenu = false;
    });
  },
  created() {
    this.$apollo.provider.defaultClient
      .query({
        query: MENU_CATEGORIES,
      })
      .then(({ data }) => {
        this.categories = data.categories;
        this.activeCategory = data.categories[0];
      });
  },
  components: {
    CatalogCategoryList,
    CatalogMenuAsideComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.catalog {
  display: flex;
  align-items: start;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--blue-50);
  margin-top: -50px;
  padding: 0 15px 100px;
  z-index: 2;

  +below(860px) {
    margin-top: 0;
    padding-bottom: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  &__container {
    padding: 32px 0 48px;
    max-width: var(--main_width);
    width: 100%;
    display: grid;
    grid-gap: 24px;

    +below(860px) {
      grid-gap: 15px;
      padding-top: 30px;
      padding-bottom: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin: 0;
    color: var(--body-color);

    +below(860px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-gap: 24px;
    align-items: start;

    +below(860px) {
      grid-template-columns: 1fr;
    }
  }

  &__body {
    display: grid;
    grid-gap: 24px;
    align-items: start;

    +below(860px) {
      display: none;
    }
  }

  &__category-title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin: 0;
  }
}
</style>
