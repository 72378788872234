<template>
  <footer class="footer">
    <MainFooterComponent />
    <SmallFooterComponent />
  </footer>
</template>

<script>
import MainFooterComponent from "components/footer/components/MainFooterComponent.vue";
import SmallFooterComponent from "components/footer/components/SmallFooterComponent.vue";

export default {
  name: "FooterComponent",
  components: { SmallFooterComponent, MainFooterComponent },
};
</script>
