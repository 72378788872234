<template>
  <header class="header">
    <HeaderTopComponent />
    <HeaderMainComponent @search="searchInput" :products="products" :cart-items-length="cartItemsLength" />
    <HeaderBottomComponent />
    <HeaderMobileComponent @search="searchInput" :products="products" :cart-items-length="cartItemsLength" />
  </header>
</template>

<script>
import HeaderTopComponent from "components/header/components/HeaderTopComponent.vue";
import HeaderMainComponent from "components/header/components/HeaderMainComponent.vue";
import HeaderBottomComponent from "components/header/components/HeaderBottomComponent.vue";
import HeaderMobileComponent from "components/header/components/HeaderMobileComponent.vue";
import PRODUCTS_PAGINATE from "gql/queries/products_paginate.graphql";

export default {
  name: "HeaderComponent",
  data() {
    return {
      products: [],
      loading: false,
    };
  },
  computed: {
    cartItemsLength() {
      return this.$store.state.auth.cart && this.$store.state.auth.cart.length
        ? this.$store.state.auth.cart.length
        : 0;
    },
  },
  methods: {
    searchInput(title) {
      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout);
      }
      this.loading = true;
      this.inputDebounceTimeout = setTimeout(() => {
        this.submitSearch(title);
      }, 500);
    },
    async submitSearch(title) {
      await this.$apollo.provider.defaultClient
        .query({
          query: PRODUCTS_PAGINATE,
          variables: {
            title: title,
            parent: true,
            first: 16,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.products = data.products_paginate.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    HeaderMobileComponent,
    HeaderBottomComponent,
    HeaderMainComponent,
    HeaderTopComponent,
  },
};
</script>
