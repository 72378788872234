var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"header-bottom__container"},[_c('ul',{staticClass:"header-bottom__list"},[_c('li',[_c('router-link',{staticClass:"header-bottom__link",attrs:{"to":{ name: 'catalog', query: { in_bestseller: true, page_title: 'Хиты продаж' } }}},[_c('RemixIconComponent',{attrs:{"category":"Finance","name":"trophy-line"}}),_c('span',[_vm._v("Хиты продаж")])],1)],1),_c('li',[_c('router-link',{staticClass:"header-bottom__link",attrs:{"to":{
            name: 'catalog',
            query: {
              ordering: JSON.stringify([{ orderColumn: 'created_at', orderBy: 'desc' }]),
              page_title: 'Новинки',
            },
          }}},[_c('RemixIconComponent',{attrs:{"category":"System","name":"star-line"}}),_c('span',[_vm._v("Новинки")])],1)],1),_vm._l((_vm.categories),function(item,i){return _c('li',{key:i},[_c('router-link',{staticClass:"header-bottom__link",attrs:{"to":{ name: 'catalog', params: { id: item.id }, query: { page_title: item.title } }}},[_vm._v("\n          "+_vm._s(item.title)+"\n        ")])],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }