<template>
  <div class="small-footer">
    <div class="small-footer__container">
      <div class="small-footer__links">
        <span>© {{ year }} Торговая площадка товаров из Турции</span>
        <div class="small-footer__link">
          <a href="/files/terms.pdf" target="_blank">Политика конфиденциальности</a>
          <a href="/files/terms.pdf" target="_blank">Соглашение об использовании</a>
        </div>
      </div>
      <div class="small-footer__bottom">
        <ul class="small-footer__pay-methods">
          <li
            v-for="(item, i) in pay_methods"
            :key="i"
            class="small-footer__pay-method"
            :class="{ 'small-footer__pay-method--purple': item.purple }"
          >
            <span :title="item.title">
              <FileIconComponent :name="item.icon" />
            </span>
          </li>
        </ul>
        <ComradesLogo />
      </div>
    </div>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import moment from "moment";
import ComradesLogo from "components/ComradesLogo.vue";

export default {
  name: "SmallFooterComponent",
  data() {
    return {
      year: moment().format("YYYY"),
      pay_methods: [
        {
          title: "Apple Pay",
          icon: "apple-pay",
        },
        {
          title: "Google Pay",
          icon: "google-pay",
        },
        {
          title: "Visa",
          icon: "visa",
        },
        {
          title: "Master Card",
          icon: "master-card",
        },
        {
          title: "Shop Pay",
          icon: "shop-pay",
          purple: true,
        },
      ],
    };
  },
  components: { ComradesLogo, FileIconComponent },
};
</script>

<style lang="stylus">
.small-footer {
  display: flex;
  align-items center
  justify-content center
  background var(--white)
  padding 0 15px

  &__container {
    max-width var(--main_width)
    width 100%
    display flex
    flex-direction column
    gap 25px
    padding 24px 0
    +below(860px) {
      padding 15px 0
    }
  }

  &__bottom {
    display flex
    justify-content space-between
    align-items center

    +below(860px) {
      flex-direction column
      gap 15px
    }
  }

  &__links {
    display flex
    align-items center
    justify-content space-between
    +below(860px) {
      flex-direction column
      gap 15px
    }
    +below(460px) {
      text-align center
      gap 10px
    }

    a {
      text-decoration underline
    }

    a
    span {
      font-weight: 300;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--gray-50);
    }
  }

  &__link {
    display flex
    gap 52px

    +below(860px) {
      gap 15px
    }
    +below(460px) {
      flex-direction column
      gap 10px
    }

    *:not(:last-child) {
      position: relative;

      &:after {
        content "|"
        absolute right -26px top bottom
        margin auto 0
        +below(960px) {
          display none
        }
      }
    }
  }

  &__pay-methods {
    display flex
    align-items center
    gap 16px
  }

  &__pay-method {
    background: var(--white);
    border: 1px solid var(--blue-300);
    box-sizing: border-box;
    border-radius: 4px;
    width 34px
    height 24px
    display inline-flex
    align-items center
    justify-content center

    a {
      display inline-flex
      align-items center
      justify-content center

      .icon {
        max-width 24px
        max-height 15px
      }
    }

    &--purple {
      background var(--purple)
    }
  }
}
</style>
